import React from "react";
import { graphql } from "gatsby";
// import { Section } from "./components/styled";
import Section from "./components/Section";
import StyledImage from "./components/StyledImage";
import Post from "./components/Post";

const Default = ({ data }) => {
    const {
        date,
        title,
        subtitle,
        image,
        sections,
        caption,
        tags,
    } = data.markdownRemark.frontmatter;
    const timeToRead = data.markdownRemark.fields.readingTime.text;

    return (
        <Post
            maxWidth={768}
            title={title}
            subtitle={subtitle}
            date={date}
            timeToRead={timeToRead}
            tags={tags}
        >
            {image && (
                <StyledImage image={image.childImageSharp.gatsbyImageData} width={100} caption={caption} />
            )}
            {sections &&
                sections.map((section, index) => {
                    return <Section key={index} section={section} />;
                })}
        </Post>
    );
};

export const query = graphql`
    query($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            frontmatter {
                date
                title
                subtitle
                tags {
                    tag
                }
                image {
                    childImageSharp {
                       gatsbyImageData(layout: CONSTRAINED) 
                    }
                }
                caption
                sections {
                    sectionTitle
                    sectionBody
                    images {
                        image {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED)
                            }
                        }
                        description
                    }
                }
            }
            fields {
                readingTime {
                    text
                }
            }
        }
    }
`;

export default Default;
