import React from "react";
import Layout from "../../Layout";
import { CenterLayout } from "../../styled";
import { PostContainer } from "../components/styled";

const PostLayout = ({ children, maxWidth }) => {
    return (
        <Layout>
            <CenterLayout maxWidth={maxWidth}>
                <PostContainer>{children}</PostContainer>
            </CenterLayout>
        </Layout>
    );
};

export default PostLayout;
