import React from "react";
import PostLayout from "./PostLayout";
import PostHeader from "./PostHeader";

const Post = ({ title, subtitle, date, timeToRead, tags, maxWidth, children }) => {
    return (
        <PostLayout maxWidth={maxWidth}>
            <PostHeader
                title={title}
                subtitle={subtitle}
                date={date}
                timeToRead={timeToRead}
                tags={tags}
            />
            {children}
        </PostLayout>
    );
};

export default Post;
