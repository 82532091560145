import React from "react";
import { Title, Subtitle, DateContainer, Tags } from "./styled";
import { format } from "date-fns";

const PostHeader = ({ title, subtitle, date, timeToRead, tags }) => {
    // console.log(tags);

    return (
        <>
            <Title>{title}</Title>
            <DateContainer>
                {format(new Date(date), "MMMM do, y")} <span> • {timeToRead}</span>
            </DateContainer>
            {tags && (
                <Tags>
                    {tags.map((tag, index) => {
                        return (
                            <div key={index}>
                                <span className="hash">#</span>
                                <span key={tag.tag + index}>{tag.tag}</span>
                            </div>
                        );
                    })}
                </Tags>
            )}
            <Subtitle>{subtitle}</Subtitle>
        </>
    );
};

export default PostHeader;
