import React from "react";
import { Section as StyledSection } from "./styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { Caption } from "./styled";

const Section = ({ index, section }) => {
    const { sectionTitle, sectionBody, images } = section;
    
    // images[3] = images[1];
    return (
        <StyledSection key={index}>
            {sectionTitle && <p>{section.sectionTitle}</p>}
            {images && <Images images={images} />}
            {sectionBody && <span>{sectionBody}</span>}
        </StyledSection>
    );
};

export default Section;

const Images = ({ images }) => {
    return (
        <div className="images">
            {images.map((image, index) => (
                // if (index > 0 && index % 2 == 0) {
                <React.Fragment key={index}>
                    {index > 0 && index % 2 === 0 && <hr />}
                    <div>
                        <GatsbyImage
                            image={image.image.childImageSharp.gatsbyImageData}
                            style={{ width: "100%" }}
                            alt=""
                        />
                        <Caption align={"center"}>{image.description}</Caption>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};
