import styled from "styled-components";

export const PostContainer = styled.div`
    height: 100%;
    background-color: white;
    margin: calc(1.875rem / 2) 0 0;
    padding: calc(1.875rem);
    width: 100%;
    box-shadow: 0 0 0 1px rgba(166, 173, 201, 0.4);
    @media screen and (min-width: 768px) {
        border-radius: 5px;
        margin: calc(1.875rem / 2) 0 calc(1.875rem / 2);
    }
`;

export const Title = styled.h1`
    /* font-family: $header-font-family; */
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: calc(1.875rem / 4);
    margin-top: 0;
`;

export const Subtitle = styled.h2`
    font-size: 1rem;
    font-weight: 300;
    font-style: italic;
    margin-top: 0;
    color: #5c677d;
`;

export const DateContainer = styled.p`
    font-weight: 600;
    font-size: 1rem;
    margin: 0 0 calc(1.875rem / 2);
    > span {
        font-weight: 400;
        font-size: 1rem;
    }
`;

export const Tags = styled.div`
    display: flex;
    margin-bottom: 1.875rem;

    > div {
        background-color: #efeff4;
        padding: 5px 7px;
        margin-right: calc(1.875rem / 2);
        cursor: pointer;
        border-radius: 5px;

        &:hover > span :not(.hash) {
            color: rgba(38, 43, 48, 1);
        }

        > span :not(.hash) {
            color: rgba(38, 43, 48, 0.8);
            transition: color cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
        }
        .hash {
            color: rgba(38, 43, 48, 0.4);
        }
    }
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: ${(props) => (props.width ? props.width + "%" : "90%")};
    margin: 0 auto;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;

    /* @include md */
    @media screen and (min-width: 768px) {
        width: ${(props) => (props.width ? props.width + "%" : "80%")};
    }
`;

export const Caption = styled.p`
    font-style: italic;
    color: #5c677d;
    font-weight: 400;
    text-align: ${(props) => (props.align ? props.align : null)};
`;

export const Section = styled.div`
    margin-top: calc(1.875rem / 2);
    margin-bottom: calc(1.875rem / 2);
    & > p {
        /* font-family: $header-font-family; */
        font-weight: 600;
        font-size: 1.5rem;
        margin: 0 0 calc(1.875rem / 4);
    }
    & > span {
        font-weight: 500;
        margin-top: calc(1.875rem / 4);
    }
    & .images {
        display: flex;
        /* grid-template-columns: repeat(2, 1fr); */
        gap: calc(1.875rem / 2);
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: calc(1.875rem / 2);
        hr {
            flex-basis: 100%;
            height: 0;
            margin: 0;
            border: 0;
        }
        > div {
            flex: 1;
            flex-grow: 1;
            flex-basis: 100%;
            /* @include md */
            @media screen and (min-width: 768px) {
                max-width: calc(50% - calc(1.875rem / 4));
                flex-basis: 0%;
            }

            /* display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; */
        }
        /* > * :last-child {
            flex-basis: 100%;
        } */
    }
`;
