import React from "react";
import { ImageContainer, Caption } from "./styled";
import { GatsbyImage } from "gatsby-plugin-image";

const StyledImage = ({ image, width, caption }) => {
    return (
        <ImageContainer width={width}>
            <GatsbyImage image={image} style={{ width: "100%" }} alt="" />
            {caption && <Caption align="center">{caption}</Caption>}
        </ImageContainer>
    );
};

export default StyledImage;
